<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <NominationsList
          :headers="headersNominations"
          :headers_extra="headersNominationsExtras"
          :items="itemsNominations"
          :items_extra="itemsNominationsExtras"
          @initialize="initialize"
          :search_tool="true"
        ></NominationsList>
      </div>
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import NominationsList from "@/views/pages/nominations/NominationsList.vue";
import genericService from "./../../../services/generic.service";
import { mapGetters } from "vuex";

export default {
  components: {
    NominationsList,
  },

  data: () => ({
    uri: "nominations",
    headersNominations: [
      //{ text: "ID", sortable: true, value: "id", filterable: true },
      { text: "NR", sortable: true, value: "nr", filterable: true },
      { text: "WO", value: "work_order", filterable: true },
      { text: "Surveyor", value: "surveyor_label", filterable: true },
      { text: "Date", value: "date", sortable: false },
      { text: "Notes", value: "description", filterable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersNominationsExtras: [
      { text: "ID", sortable: true, value: "id", filterable: true },
      { text: "Description", value: "description", sortable: false },
      { text: "Port", value: "place", sortable: false },
      { text: "Agent", value: "agent_label", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsNominations: [],
    itemsNominationsExtras: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordListWithLimit(
        "/" + this.uri,
        100,
        "date",
        _self.currentUser.role,
        _self.currentUser.id,
        function (records) {
          _self.itemsNominations = records;
        }
      );
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Nominations" }]);
  },
  computed: {
      ...mapGetters(["currentUser"]),
  },
};
</script>
