<template>
  <v-data-table
    :headers="headers"
    :items="agentDetailItems"
    item-key="id"
    sort-by="id"
    sort-desc
    class="elevation-1"
    :loading="loading"
    loading-text="Loading... Please wait"
    :single-expand="true"
    :expanded.sync="expanded"
    show-expand
    @current-items="currentItems"
    :hide-default-footer="true"
    disable-pagination
  >
    <template v-slot:top>
      <v-toolbar>
        <v-toolbar-title>Agent Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px">
          <template v-slot:activator="{ on }">
            <v-btn color="gray" dark class="mb-2" v-on="on" @click="formNewItem()">New Line</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.place"
                      label="Port"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.agent"
                      label="Agent"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save(items)">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    
    <template slot="items" scope="props">
          <td class="text-xs-right">{{ props.item.place }}</td>
          <td>{{ props.item.id }}</td>
      </template>

    
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        v-b-tooltip.hover
        title="Edit"
      >mdi-pencil</v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
        v-b-tooltip.hover
        title="Delete"
      >mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri:"agentdetails",
    agentDetailItems: [],
    today: new Date().toISOString().substr(0, 10),
    formNewtItem: true,
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    popDatepick1: false,
    popDatepick2: false,
    radios: "",
    totalAmount: 0,
    editedIndex: -1,
    editedItem: {
      id: "",
      description: "",
      place: "",
      agent: "",
      agent_label: "",
      parent_id: "",
      parent_type: ""
    },
    defaultItem: {
      id: "",
      description: "",
      place: "",
      agent: "",
      agent_label: "",
      parent_id: "",
      parent_type: ""
    },
    switch1: true,

    /* Filtros & Loading */
    loading: false,
    filters: [
      { text: "All", value: 99 },
      { text: "Description", value: 0 },
      { text: "Total", value: 1 }
    ],
    date1: "",
    date2: "",
    formatedDates: "",
    rangeDates: [],
    rangeCalendar: false,
    dialogFilters: false,
    itemsFiltered: 0
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Line" : "Edit Line";
    },
    /* Filters */
    dateRangeText() {
      return this.formatDate(this.rangeDates);
    },
    ...mapGetters([ 'currentUser' ])
  },

  props: ["headers", "items", "data_table_top", "data_table_footer", "search_tool", "current_nomination_id"],

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    editItem(item) {
      this.formNewtItem = false;
      this.dialog = true;
      this.editedIndex = this.agentDetailItems.indexOf(item);
      this.editedItem = item;
    },

    deleteItem(item) {
      
      var list = this.agentDetailItems;

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = list.indexOf(item);
        
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          list.splice(index, 1);
          
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      /* Listado de Dialogs */
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save(items) {
      var _self = this;
      
      if (_self.editedIndex > -1) {
        items[_self.editedIndex] = _self.editedItem;
        genericService.updateRecord("/"+_self.uri+"/" + _self.editedItem.id, _self.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        _self.editedItem.parent_id = _self.current_nomination_id;
        _self.editedItem.parent_type = "nominations";
        _self.agentDetailItems.push(_self.editedItem);
        genericService.createRecord("/"+_self.uri, _self.editedItem, function(rta){
          if(rta != undefined) {
            Swal.fire({
              title: "",
              text: "Record has been successfully saved!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            _self.editedItem.id = rta.id;
          }
        });

      }
      this.close();
    },

    reset() {
      this.$emit("initialize");
    },

    toNumber(nr) {
      nr = parseFloat(nr);
      return nr;
    },

    formatAmount(nr) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
      });

      return formatter.format(nr);
    },

    /* Filtros & Loading */
    currentItems(items) {
      this.loading = true;
      setTimeout(() => {
        this.itemsFiltered = items.length;

        // Juntar numeros y formatearlos
        items = items.reduce((a, b) => a + b.total, 0);
        this.totalAmount = this.formatAmount(items);

        this.loading = false;
      }, 300);
    },

    filterItems(itemFiltered) {
      if (itemFiltered == 99 || itemFiltered == null) {
        this.headers.forEach(header => (header.filterable = true));
      } else {
        this.headers.forEach(header => (header.filterable = false));
        this.headers[itemFiltered].filterable = true;
      }
      this.dialogFilters = false;
      return this.headers;
    },
    formatDate(dates) {
      if (dates[1]) {
        const [year1, month1, day1] = dates[0].split("-");
        const [year2, month2, day2] = dates[1].split("-");
        this.date1 = `${day1}/${month1}/${year1}`;
        this.date2 = `${day2}/${month2}/${year2}`;

        this.formatedDates = this.date1 + " ~ " + this.date2;
      } else {
        this.formatedDates = "";
      }
      return this.formatedDates;
    },
    focusField(target) {
      setTimeout(() => {
        target.focus();
      }, 500);
    },
    formNewItem(){
      this.formNewtItem = true;
    }
  },

  mounted() {
    if(this.search_tool == true){
      this.focusField(this.$refs.search);
    }
  },

  created() {
    var _self = this;
    genericService.getRelatedList(_self.uri, 'nominations', _self.current_nomination_id, function(records){
      _self.agentDetailItems = records;
    });
  },
};
</script>
